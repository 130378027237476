import React from 'react'
import MetaData from '../../components/MetaData'
import styled from 'styled-components'
import PageWrapper from '../../components/PageWrapper'
import Hero from '../../sections/common/Hero'
import { Container, Row, Col } from 'react-bootstrap'
import { Section, Box, Title, Text } from '../../components/Core'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useSelector } from 'react-redux'

const TextStyled = styled(Text)`
  margin-bottom: 1em;
`

const Intelsath = () => {
  const { t } = useTranslation( ['intelsath'] , { useSuspense: false })
  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false

  return (
    <>
      <MetaData page="default" />
      <PageWrapper footerDark>
        <Hero title="Intelsath LLC">{t('testimonial')}</Hero>

        <Section bg={userDarkMode ? 'black' : 'bg'}>
          <Container role="main" id="content">
            <Row>
              <Col lg="12" xl="8" className="offset-xl-1">
                <Box pb={['1em', null, '2em']}>
                  <Title
                    color={userDarkMode ? 'primaryLight' : 'primary'}
                    variant="card"
                    mb="18px"
                  >
                    {t(`articleTitle`)}
                  </Title>
                  <TextStyled
                    color={userDarkMode ? 'light' : 'dark'}
                    variant="small"
                  >
                    {t(`firstP`)}
                  </TextStyled>
                  <TextStyled
                    color={userDarkMode ? 'light' : 'dark'}
                    variant="small"
                  >
                    {t(`secondP`)}
                  </TextStyled>
                  <TextStyled
                    color={userDarkMode ? 'light' : 'dark'}
                    variant="small"
                  >
                    {t(`thirdP`)}
                  </TextStyled>
                  <TextStyled
                    color={userDarkMode ? 'light' : 'dark'}
                    variant="small"
                  >
                    {t(`fourthP`)}
                  </TextStyled>
                  <TextStyled
                    color={userDarkMode ? 'light' : 'dark'}
                    variant="small"
                  >
                    - Luis Cruz
                  </TextStyled>
                </Box>
                <Box pb={['1em', null, '2em']}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.intelsath.com/"
                  >
                    <Text color={userDarkMode ? 'light' : 'dark'}>
                      Visit intelsath.com
                    </Text>
                  </a>
                </Box>
                <Box>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://twitter.com/intelsath"
                  >
                    <Text color={userDarkMode ? 'light' : 'dark'}>
                      Visit twitter.com/intelsath
                    </Text>
                  </a>
                </Box>
              </Col>
            </Row>
          </Container>
        </Section>
      </PageWrapper>
    </>
  )
}
export default Intelsath
